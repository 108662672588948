
// @group document

export default {
  name: 'MainPageComponent',
  props: ['data'],
  data() {
    return {
      show: false
    }
  },
  mounted() {
    this.$nextTick(() => (this.show = true))
  }
}
